@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

/* components/LandingPage.css */

.landing-page {
    /* check if we can change the font to Montserrat */
    font-family: 'Arial, sans-serif';
  }
  
  /* Header */
  .landing-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 20px;
    background-color: #4A90E2;
  }
  
  .landing-header .logo {
    font-size: 1.5em;
    color: #FFFFFF;
    font-weight: bold;
  }
  
  .landing-header nav button {
    background: none;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .landing-header nav button:hover {
    background-color: #FFFFFF;
    color: #4A90E2;
  }
  
  /* Hero Section */
  .hero-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 64px 32px;
    background-color: #F5F7FA;
  }
  
  .hero-text {
    flex: 1;
    min-width: 300px;
  }
  
  .hero-text h1 {
    font-size: 2.5em;
    color: #4A4A4A;
  }
  
  .hero-text p {
    font-size: 1.2em;
    margin: 16px 0;
  }
  
  .hero-text button {
    background-color: #F5A623;
    color: #FFFFFF;
    border: none;
    padding: 12px 24px;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .hero-text button:hover {
    background-color: #D48806;
  }
  
  .hero-image {
    flex: 1;
    min-width: 300px;
    padding: 5px;
       
    text-align: center;
  }
  
  .hero-image img {
    max-width: 100%;
    border-radius: 20px; 
    height: auto;
  }
  
  /* Features Section */
  .features-section {
    padding: 64px 32px;
    background-color: #FFFFFF;
    text-align: center;
  }
  
  .features-section h2 {
    font-size: 2em;
    margin-bottom: 32px;
  }
  
  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .feature {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    margin: 16px;
    text-align: left;
  }
  
  .feature h3 {
    color: #4A90E2;
    font-size: 1.5em;
  }
  
  .feature p {
    color: #4A4A4A;
  }
  
  /* How It Works Section */
  .how-it-works-section {
    padding: 64px 32px;
    background-color: #F5F7FA;
  }
  
  .how-it-works-section h2 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 32px;
  }
  
  .how-it-works-section ol {
    max-width: 600px;
    margin: 0 auto;
    font-size: 1.2em;
  }
  
  .how-it-works-section li {
    margin-bottom: 16px;
  }
  
  /* Pricing Section */
  .pricing-section {
    padding: 64px 32px;
    background-color: #FFFFFF;
    text-align: center;
  }
  
  .pricing-section h2 {
    font-size: 2em;
    margin-bottom: 32px;
  }
  
  .pricing {
    display: flex;
    justify-content: center;
  }
  
  .pricing-card {
    background-color: #F5F7FA;
    padding: 32px;
    margin: 16px;
    border-radius: 8px;
    max-width: 300px;
  }
  
  .pricing-card h3 {
    font-size: 1.5em;
    margin-bottom: 16px;
  }
  
  .pricing-card p {
    font-size: 1.2em;
    margin-bottom: 24px;
  }
  
  .pricing-card button {
    background-color: #50E3C2;
    color: #FFFFFF;
    border: none;
    padding: 12px 24px;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pricing-card button:hover {
    background-color: #3AC7A7;
  }
  
  /* Footer */
  .landing-footer {
    background-color: #4A4A4A;
    color: #FFFFFF;
    padding: 16px 32px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-links a {
    color: #FFFFFF;
    margin-left: 16px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
    }
  
    .features {
      flex-direction: column;
    }
  
    .pricing {
      flex-direction: column;
    }
  
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-links {
      margin-top: 16px;
    }
  }
  