@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap');

.App {
text-align: center;
font-family: 'Montserrat', sans-serif;
}

.App button {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
}


.buttons-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.buttons-home button {
    width: 100%;
    max-width: 220px;
}

.exercise-button {
padding: 10px 20px;
/* width: 220px; */
font-size: 1.2rem;
border-radius: 20px;
background-color: #2196F3;
color: white;
border: none;
cursor: pointer;
margin: 10px;
font-family: 'Montserrat', sans-serif;
}

.exercise-button.disabled {
background-color: #d3d3d3;
cursor: not-allowed;
}

.close-button {
    position: fixed;
    top: 5px;
    right: 5px;
    border: none;
    background-color: inherit;
    cursor: pointer;
}

.dummyButton{
    background-color: inherit;
    border: none;
    cursor: pointer;
}

.close-button span {
    color: red;
    font-size: 1.8rem;
}

.topic-select {
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-gap: 10px;
justify-content: center;
margin-top: 50px;
}

.topic-button {
padding: 10px 20px;
font-size: 1.2rem;
border-radius: 20px;
background-color: #FF5722;
color: white;
border: none;
cursor: pointer;
}

.spinner {
display: inline-block;
width: 50px;
height: 50px;
border: 5px solid rgba(0, 0, 0, 0.1);
border-radius: 50%;
border-top-color: green;
animation: spin 1s linear infinite;
margin: 20px auto;
}

@keyframes spin {
to { transform: rotate(360deg); }
}

.loading-container {
display: flex;
flex-direction: column;
align-items: center;
}

.reading-block {
margin: 20px;
text-align: left;
background-color: #f9f9f9;
padding: 20px;
border-radius: 10px;
border: 1px solid #ddd;
}



.reading-block.centered {
    text-align: center;
    display: inline-block;
}

.just-row-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.answer-reading-block {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submit-button {
padding: 10px 20px;
font-size: 1.2rem;
border-radius: 20px;
background-color: #4CAF50;
color: white;
border: none;
cursor: pointer;
margin-top: 20px;
}

/* <timer> */
.timer-block {
  font-size: 1.2rem; /* Large, easy-to-read font size */
  font-weight: bold; /* Bold font for emphasis */
  color: #4CAF50; /* Green color for normal time */
  background-color: #f9f9f9; /* Light background for contrast */
  padding: 10px 15px; /* Some padding for spacing */
  border-radius: 10px; /* Rounded corners for a smooth look */
  border: 2px solid #ddd; /* Subtle border for structure */
  display: inline-block; /* Make it inline-block to adjust size naturally */
  margin: 10px 0; /* Space above and below the timer */
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;

}
.timer-block-time {
    font-family: monospace; /* ensure the same size for each number in the timer */
}

.timer-block.low-time {
  color: #FF5722; /* Change to red when time is running out */
  background-color: #ffe6e6; /* Light red background to increase urgency */
}

.timer-block.negative-time {
  color: #FF0000; /* Bright red for overtime */
  background-color: #fff5f5; /* Slightly different background for negative time */
}
/* </timer> */


/* <Discussion Task> */
.discussion-task {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    text-align: left;
    max-width: 1100px;
  }
  
  .discussion-column1, .discussion-column2 {
    width: 48%;
  }
  
  .discussion-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .discussion-student {
    font-weight: bold;
    width: 20%;
  }
  
  .discussion-text {
    width: 75%;
  }
  
/* Writing Area Styles */
.writing-area {
    margin-top: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
  }
  
  .writing-area .submit-button {
    margin-top: 10px; /* Space between the textarea and the submit button */
  }

/* </Discussion Task> */


/* <reading exercise>  */

.exercise-button.smaller {
    padding: 5px 10px;
    font-size: 1.0rem;
}

.reading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
}

.question-container {
    flex: 1;
    /* width: 60%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.timer-progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.question-content {
    text-align: left;
    max-width: 800px;
    margin: 20px 0;
    width: 100%; 
    background-color: rgb(177, 197, 241);
    border-radius: 10px;   
    padding: 10px;
}

.nav-button {
    padding: 10px 20px;
    margin: 10px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.reading-passage {
    flex: 1;
    width: 40%;
    height: 100%;
    text-align: left;
    overflow-y: auto;
    background-color: #f9f9f9;
    padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #ddd;
}

.target-paragraph {
    background-color: rgb(173, 230, 200);
    padding: 2px 10px;
    border-radius: 10px;
}

.paragraph {
    padding: 2px 10px;
    background-color: rgb(241, 241, 230);
    border-radius: 10px;
}

.marked-text {
    background-color: lightgrey;
}


.reading-passage h2 {
    text-align: center;
    margin-bottom: 20px;
}
/* </reading exercise>  */


.correct-answer {
    color: green;
    font-weight: bold;
}

.incorrect-answer {
    color: red;
    font-weight: bold;
}

.reasoning-text {
    margin-top: 5px;
    font-style: italic;
}

/* Listening */

.padded-reasoning {
    padding: 10px;
    margin: 10px;
}

.next-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin: 10px 5px;
    font-size: 1.2rem;
}
.next-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

.nav-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}



/* img lecture */
.img-container {
    margin-top: 20px;
}

.lecture-img {
    max-width: 25%; /* Make the image smaller */
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); 
    margin: 0 auto; /* centers the img */
}


/* styled table */

.styled-table {
    border-collapse: collapse; /* Ensures borders don't double */
    width: 100%;
}

.styled-table th, .styled-table td {
    border: 1px solid #dddddd; /* Border between cells */
    padding: 8px;
}

.styled-table th {
    background-color: #f2f2f2; /* Light background for headers */
    text-align: center;
}

.styled-table td {
    text-align: center; 
}

.correction-mark {
    font-size: 1.2rem;
    padding: 5px;
    border-radius: 10px;
}

.question-ratio-timer{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;
    align-items: center;
}



.listeningQ-button-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* Listening overview */

/* <overview> */


.listening-overview h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
}

/* Correct and Incorrect Icons */
.correct-icon {
    color: green;
}

.incorrect-icon {
    color: red;
}

/* General enhancements */
.exercise-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 10px;
    background-color: #4CAF50;
    color: white;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}

.exercise-button:hover {
    background-color: #45a049;
}

.exercise-button:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Utility */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}


/* extra css */


/* Container for the overview */
.listening-overview {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
}

/* Grid for questions */
.listening-questions-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Two columns */
    gap: 20px; /* Space between the cards */
    margin-top: 20px;
    /* grid-auto-flow: column; */
}

/* Individual question card */
.listening-question-card {
    display: flex;
    justify-content: space-between;
    align-items: center; /* center vertically */
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Hover effect */
.listening-question-card:hover {
    background-color: #f1f1f1;
}

/* Button styling */
.listening-question-card button {
    background-color: #2196F3;
    color: white;
    padding: 5px 15px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.listening-question-card button:hover {
    background-color: #1976D2;
}

.listening-question-card button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

.scores-time-listening{
    display: flex;
    justify-content: space-between;

}

/* Styling for the score-time-listening header */
.scores-time-listening {
    display: flex;
    justify-content: space-around; /* Distribute items evenly */
    align-items: center; /* Align vertically in the middle */
    padding: 15px 20px; /* Add some padding around the container */
    background-color: #f1f1f1; /* Light background color */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 20px; /* Space below the header */
}

.scores-time-listening span {
    font-size: 1.1rem; /* Slightly larger text */
    font-weight: bold; /* Emphasize the score text */
    color: #333; /* Darker text color */
}


/* Section header styles */
.listening-section-header {
    text-align: left;
    width: 100%;
}

/* Question card layout when the section starts */
.listening-section-header .section-type {
    font-size: 0.95 rem;
    font-weight: bold;
}

.question-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Background color for grouped questions */
.listening-question-card.group-start {
    background-color: #f0f0f0; /* Light gray background for grouped questions */
}

.listening-question-card {
    padding: 15px;
    background-color: #ffffff; /* Default card background */
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Hover effect */
.listening-question-card:hover {
    background-color: #f1f1f1;
}

/* </overview> */


/* <transcripts> */

.transcripts-container {
    width: 90%;
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    text-align: left;
  }

  .transcript-selection-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* flex start on the x-axis (main is y) */
}

.dummy-center-container{
    display: flex;
    padding: 10px;
    flex: 1; 
    justify-content: center; /* main centering */ 
    align-items: flex-start;
}
  
  .transcript-section {
    margin-bottom: 20px;
  }
  
  .transcript-header {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .transcript-content {
    flex: 1; /* ensures horizontal space in flex parent is equally distr. */
    background-color: #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .transcript-text {
    margin-top: 10px;
    font-size: 1.1rem;
  }
  
  
  .not-text-container{
    display: flex;
    flex-direction: column;
}

.button-header {
    display: flex;
    flex-direction: row;
}


/* </transcripts> */

/* <speaking> */

.limited-reading-block {
    max-width: 800px;
}

.speaking-reading-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 600px;
    margin: 20px auto; /* auto centers the text horizontally */
    text-align: left;
    background-color: #fff8e1;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ffd54f;
    text-align: left;
  }

  .speaking-reading-block.mini {
    padding: 5px;
    max-width: 400px;
  }

  .speaking-user-answer {
    white-space: pre-wrap;
  }
  
  .speaking-reading-block h4 {
    text-align: center;
    margin: 5px;
    width: 100%;
  }

  .speaking-reading-block.correction {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px;
  }

  .speaking-reading-block audio {
    margin: 10px 0;
    width: 100%;
  }
  
  .speaking-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* shop */
  .price-tag {
    border-radius: 10px;
    border: 1px solid #ffd54f;
    padding: 10px;
    margin: 10px;
    font-size: 1.5rem;
    background-color: #fff8e1;
  }